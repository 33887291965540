import React from "react";
import { Auth } from "aws-amplify";
import { RightContainer as Container, Form } from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { useNotificationContext } from "../UtilComponents/Notification/NotificationProvider";
import { STATES } from "../../utils/constants";
import { InputEmail, ButtonSubmit, SpanBackToLogin } from "../Components";

const ForgotPassword = () => {
  const { setState, inputs } = useStateContext();
  const { createNotification } = useNotificationContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await Auth.forgotPassword(inputs.email);
      setState(STATES.FORGOT_PASSWORD_SUBMIT);
    } catch (err) {
      console.error(err.message);
      createNotification({ type: "error", message: err.message, time: 5000 });
    }
  };

  return (
    <Container>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <InputEmail placeholder='Digite seu e-mail' required />
        <ButtonSubmit title='Enviar Código' />
      </Form>
      <SpanBackToLogin title='Voltar para o login' />
    </Container>
  );
};

export default ForgotPassword;
