import Cookie from "js-cookie";
import { redirectToLogin } from "./url";

export const createCookie = (value) => {
  if (!value) {
    redirectToLogin();
  }

  if (window.location.origin.includes("localhost")) {
    Cookie.set(process.env.REACT_APP_TOKEN, value);
  }

  Cookie.set(process.env.REACT_APP_TOKEN, value, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const clearCookie = () => {
  localStorage.clear();
  if (window.location.origin.includes("localhost")) {
    Cookie.remove(process.env.REACT_APP_TOKEN);
  }
  Cookie.remove(process.env.REACT_APP_TOKEN, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};
