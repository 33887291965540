import React from "react";
import { ButtonTransparent, Button } from "../../../../styles/GlobalStyles";
import { useStateContext } from "../../../_StateHandler/StateContenxt";
import { STATES } from "../../../../utils/constants";

const ButtonSignUp = ({ transparent, title, fontSize, textTransform }) => {
  const { setState } = useStateContext();
  return (
    <>
      {transparent ? (
        <ButtonTransparent
          fontSize={fontSize}
          textTransform={textTransform}
          onClick={(e) => setState(STATES.SIGN_UP)}
        >
          {title || "Cadastrar"}
        </ButtonTransparent>
      ) : (
        <Button
          fontSize={fontSize}
          textTransform={textTransform}
          onClick={(e) => setState(STATES.SIGN_UP)}
        >
          {title || "Cadastrar"}
        </Button>
      )}
    </>
  );
};

export default ButtonSignUp;
