import React from "react";
import { Input } from "../../../../styles/GlobalStyles";
import { INPUTS } from "../../../../utils/constants";
import { useStateContext } from "../../../_StateHandler/StateContenxt";

const InputEmail = ({ defaultValue, placeholder, required }) => {
  const { updateInputs } = useStateContext();

  return (
    <Input
      name={INPUTS.EMAIL}
      type={INPUTS.EMAIL}
      defaultValue={defaultValue}
      placeholder={placeholder || ""}
      required={required || true}
      onChange={(e) => updateInputs(e)}
    />
  );
};

export default InputEmail;
