import React from "react";
import { Auth } from "aws-amplify";
import {
  RightContainer as Container,
  Form,
  SpansContainer,
} from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { useNotificationContext } from "../UtilComponents/Notification/NotificationProvider";
import { STATES } from "../../utils/constants";
import {
  InputVerificationCode,
  InputPassword,
  ButtonSubmit,
  SpanBackToLogin,
  SpanResendCode,
  FormInfo,
} from "../Components";

const ForgotPasswordSubmit = () => {
  const { setState, inputs } = useStateContext();
  const { createNotification } = useNotificationContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await Auth.forgotPasswordSubmit(
        inputs.email,
        inputs.verificationCode,
        inputs.password
      );
      createNotification({
        type: "success",
        message: "Senha alterada com sucesso.",
        time: 5000,
      });
      setState(STATES.SIGN_IN);
    } catch (err) {
      console.error(err.message);
      createNotification({ type: "error", message: err.message, time: 5000 });
    }
  };

  return (
    <Container>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FormInfo description='Insira o código e a nova senha' />
        <InputVerificationCode placeholder='Código' required />
        <InputPassword placeholder='Nova senha' required hasVisibilityToggle />
        <ButtonSubmit title='Trocar a senha' />
      </Form>
      <SpansContainer>
        <SpanBackToLogin title='Voltar para o login' />
        <SpanResendCode title='Reenviar código' />
      </SpansContainer>
    </Container>
  );
};

export default ForgotPasswordSubmit;
