import { STATES } from "./constants";

export const getUrlOrigin = () => {
  try {
    const { origin } = handleUrlParams();
    const redirect = origin.length && origin[0] ? atob(origin[0]) : "";
    return redirect;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const getUrlMsg = () => {
  try {
    const { msg } = handleUrlParams();
    return msg;
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const handleUrlParams = () => {
  const params = window.location.search.split(/[?=]+/);
  const origin = params[1] ? params[2].split(/[&=]+/) : "";
  const msg = params[2] || "";

  return { origin, msg };
};

export const isValidState = (state) => {
  if (state) {
    const validStates = Object.values(STATES);
    const isValid = validStates.find(
      (validState) => validState.toLowerCase() === state.toLowerCase()
    );
    return isValid;
  }

  return false;
};

export const hasAdmin = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("adm") === "true";
};

export const redirectTo = (redirect = "") => {
  if (redirect) {
    window.location.href = redirect;
  } else if (hasAdmin()) {
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}`;
  } else {
    window.location.href = `${process.env.REACT_APP_ACADEMY_URL}`;
  }
};

export const redirectToLogin = (redirect = "") => {
  if (redirect) {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(redirect)}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(redirect)}`;
    }
  } else {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(
        `${process.env.REACT_APP_ADMIN_URL}`
      )}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(
        `${process.env.REACT_APP_ACADEMY_URL}`
      )}`;
    }
  }
};
