import React from "react";
import { Section, Page, PageBackground } from "../../styles/GlobalStyles";
import { LogoCard, CompleteNewPassword } from "../../components/Components";

const CompleteNewPasswordPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard />
        <CompleteNewPassword />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default CompleteNewPasswordPage;
