import React from "react";
import { Container, Logo } from "./styles";
import BackgroundImage from "../../../assets/images/left-container-background.png";
import LogoUrl from "../../../assets/images/logo.png";

const LogoCard = ({ children }) => {
  return (
    <Container url={BackgroundImage}>
      <Logo src={LogoUrl} alt='Logo' />
      {children}
    </Container>
  );
};

export default LogoCard;
