import React from "react";
import { Auth } from "aws-amplify";
import { RightContainer as Container, Form } from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { useNotificationContext } from "../UtilComponents/Notification/NotificationProvider";
import { STATES } from "../../utils/constants";
import {
  FormInfo,
  InputName,
  InputEmail,
  InputPassword,
  ButtonSubmit,
  InputPasswordConfirm,
} from "../Components";

const SignUp = () => {
  const { setState, inputs } = useStateContext();
  const { createNotification } = useNotificationContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const username = inputs.email;
      const password = inputs.password;
      const passwordConfirm = inputs.passwordConfirm;
      const name = inputs.name;

      if (password === passwordConfirm) {
        await Auth.signUp({
          username,
          password,
          attributes: {
            name,
          },
        });

        createNotification({
          type: "success",
          message: "Usuario criado com sucesso.",
          time: 5000,
        });

        setState(STATES.CONFIRM_SIGN_UP);
      } else {
        createNotification({
          type: "warning",
          message: "Senhas precisam ser iguais.",
          time: 5000,
        });
      }
    } catch (err) {
      console.error(err.message);
      createNotification({ type: "error", message: err.message, time: 5000 });
    }
  };

  return (
    <Container>
      <FormInfo
        // title='Zaraplast'
        description='Crie a sua conta e acesse nossa plataforma'
      />
      <Form onSubmit={(e) => handleSubmit(e)}>
        <InputEmail placeholder='Digite seu e-mail' required />
        <InputName placeholder='Digite seu nome completo' required />
        <InputPassword
          placeholder='Senha'
          minlength='6'
          required
          hasVisibilityToggle
        />
        <InputPasswordConfirm
          placeholder='Confirmar senha'
          minlength='6'
          required
          hasVisibilityToggle
        />
        <ButtonSubmit title='Cadastrar' />
      </Form>
    </Container>
  );
};

export default SignUp;
