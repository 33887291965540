import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  RightContainer as Container,
  Form,
  SpansContainer,
  TitleAdmin,
} from "../../styles/GlobalStyles";
import { useStateContext } from "../_StateHandler/StateContenxt";
import { useNotificationContext } from "../UtilComponents/Notification/NotificationProvider";
import { STATES } from "../../utils/constants";
import {
  setCognitoCookies,
  isUserNotConfirmed,
  isNewPasswordRequired,
  getCurrentSession,
} from "../../utils/auth";
import { hasAdmin } from "../../utils/url";
import {
  FormInfo,
  InputEmail,
  InputPassword,
  ButtonSubmit,
  SpanForgotPassword,
} from "../Components";

const SignIn = () => {
  const { setState, setUser, inputs } = useStateContext();
  const { createNotification } = useNotificationContext();

  useEffect(() => {
    const isLogged = async () => {
      try {
        const currentSession = await getCurrentSession();
        if (currentSession) {
          setState(STATES.SIGNED_IN);
        }
      } catch (err) {
        setState(STATES.SIGNED_IN);
        console.error(err);
      }
    };
    isLogged();
  }, [setState]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const username = inputs.email;
      const password = inputs.password;

      const user = await Auth.signIn(username, password);

      if (isNewPasswordRequired(user.challengeName)) {
        setUser(user);
        setState(STATES.COMPLETE_NEW_PASSWORD);
      } else {
        setCognitoCookies(user.storage);
        setState(STATES.SIGNED_IN);
      }
    } catch (err) {
      console.error(err);
      isUserNotConfirmed(err.code) && setState(STATES.CONFIRM_SIGN_UP);
      createNotification({ type: "error", message: err.message, time: 5000 });
    }
  };

  return (
    <Container>
      {hasAdmin() && <TitleAdmin>Painel Administrativo</TitleAdmin>}
      <FormInfo
        // title='Zaraplast'
        description='Faça o login e acesse nossa plataforma'
      />
      <Form onSubmit={(e) => handleSubmit(e)}>
        <InputEmail placeholder='E-mail' required />
        <InputPassword placeholder='Senha' required hasVisibilityToggle />
        <ButtonSubmit title='Entrar' />
      </Form>

      <SpansContainer>
        <SpanForgotPassword title='Esqueci a senha' />
      </SpansContainer>
    </Container>
  );
};

export default SignIn;
