import React from "react";
import { Section, Page, PageBackground } from "../../styles/GlobalStyles";
import { LogoCard, ForgotPassword } from "../../components/Components";

const ForgotPasswordPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard />
        <ForgotPassword />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default ForgotPasswordPage;
