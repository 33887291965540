import React from "react";
import {
  Section,
  Page,
  PageBackground,
  SpansContainer,
  Span,
} from "../../styles/GlobalStyles";
import { LogoCard, SignUp, SpanBackToLogin } from "../../components/Components";

const SignUpPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard>
          <SpansContainer margin='20px 0' justifyContent='center'>
            <Span textColor='#fff' fontSize='16px' margin='0'>
              Já se cadastrou?
            </Span>
            <SpanBackToLogin
              title='Faça seu login'
              textColor='#fff'
              fontSize='14px'
              margin='0 0 0 5px'
            />
          </SpansContainer>
        </LogoCard>
        <SignUp />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default SignUpPage;
