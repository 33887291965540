import React from "react";
import { Section, Page, PageBackground } from "../../styles/GlobalStyles";
import { LogoCard, ConfirmSignUp } from "../../components/Components";

const ConfirmSignUpPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard />
        <ConfirmSignUp />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default ConfirmSignUpPage;
