import React from "react";
import { Section, Page, PageBackground } from "../../styles/GlobalStyles";
import { LogoCard, Logout } from "../../components/Components";

const LogoutPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard />
        <Logout />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default LogoutPage;
