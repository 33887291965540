import React from "react";
import { Section, Page, PageBackground } from "../../styles/GlobalStyles";
import { LogoCard, ForgotPasswordSubmit } from "../../components/Components";

const ForgotPasswordSubmitPage = () => {
  return (
    <Page>
      <Section>
        <LogoCard />
        <ForgotPasswordSubmit />
      </Section>
      <PageBackground />
    </Page>
  );
};

export default ForgotPasswordSubmitPage;
