import React from "react";
import {
	Section,
	Page,
	PageBackground,
	SpansContainer,
	Span,
} from "../../styles/GlobalStyles";
import {
	LogoCard,
	SignIn,
	ButtonSignUp,
} from "../../components/Components";

const SignInPage = () => {
	return (
		<Page>
			<Section>
				<LogoCard>
					<SpansContainer margin='20px 0' justifyContent='center'>
						<Span textColor='#fff' fontSize='16px' margin='0'>
							É o primeiro acesso?
            </Span>

					</SpansContainer>
					<ButtonSignUp
						transparent
						fontSize='13px'
						textTransform='none'
						title='Cadastre-se aqui'
					/>
				</LogoCard>
				<SignIn />
			</Section>
			<PageBackground />
		</Page>
	);
};

export default SignInPage;
