import styled from "styled-components";
import { LeftContainer } from "../../../styles/GlobalStyles";

export const Container = styled(LeftContainer)`
  background-color: #eaeaea;
  background-image: linear-gradient(
      180deg,
      rgba(32, 19, 19, 0.67),
      rgba(32, 19, 19, 0.67)
    ),
    url(${({ url }) => url});
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
`;

export const Logo = styled.img`
  width: 240px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
`;

export const Title = styled.h2`
  padding-top: 20px;
  color: #333;
  font-size: 22px;
  font-weight: 900;
  line-height: 20px;
  text-align: center;
`;
