import styled, { createGlobalStyle } from "styled-components";
import BackgroundImage from "../assets/images/signin-background.jpg";

export default createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

*, button, input {
    border: 0;
    outline: 0;
    font-family: 'Montserrat', sans-serif;
}

:root {
    --primary-color: #00205b;
    --secondary-color: #2D348D;
}

`;
export const Page = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageBackground = styled.div`
  z-index: -1;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #0e0e0e;
  background-image: linear-gradient(
      180deg,
      rgba(14, 14, 14, 0.87),
      rgba(14, 14, 14, 0.87)
    ),
    url(${({ url }) => url || BackgroundImage});
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
`;

export const Section = styled.section`
  width: 800px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  box-shadow: 0 3px 20px -1px #000;

  @media screen and (max-width: 991px) {
    width: 700px;
  }

  @media screen and (max-width: 768px) {
    width: 400px;
    height: 600px;
    flex-direction: column;
  }

  @media screen and (max-width: 575px) {
    width: 95%;
    height: 90%;
  }
`;

export const LeftContainer = styled.div`
  margin: 0 auto 0 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 18px 0 0 18px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 45%;
    border-radius: 18px 18px 0 0;
  }
`;

export const RightContainer = styled.div`
  margin: 0 0 0 auto;
  padding: 30px;
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 18px 18px 0;
  background-color: white;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 55%;
    border-radius: 0 0 18px 18px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  margin: 0 0 15px 0;
  padding: 20px;
  width: 260px;
  height: 40px;

  border: 1px solid #cccccc;
  background-color: #e6e6e6;
  border-radius: 18px;
  border-style: none;

  color: #0e0e0e;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;

  &:focus,
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Button = styled.button`
  margin: 0 0 25px 0;
  width: 264px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: ${({ fontSize }) => fontSize || "14px"};
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};

  border-radius: 18px;
  background-color: var(--secondary-color);

  cursor: pointer;

  transition: transform 500ms ease;
  &:hover {
    transform: translate(0px, -3px);
  }
`;

export const ButtonTransparent = styled(Button)`
  background-color: transparent;
  border: 1px solid ${({ borderColor }) => borderColor || "#fff"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};

  &:hover {
    background-color: var(--secondary-color);
    border: none;
  }
`;

export const Span = styled.span`
  margin: ${({ margin }) => margin || "0 0 10px"};
  color: ${({ textColor }) => textColor || "inherit"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  line-height: 20px;
  cursor: pointer;
`;

export const SpansContainer = styled.div`
  margin: ${({ margin }) => margin || "0"};
  padding: 0 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "space-evenly"};
`;

export const TitleAdmin = styled.h5`
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 22px;
  text-align: center;
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 5px;
    color: #fff;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`;
